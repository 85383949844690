<template>
    <main id="home-business" class="page">
        <section class="jumbotron jumbotron-fluid bg-jumbotron">
            <div class="container">
                <h1 class="display-4 mb-4">主营业务</h1>
                <p class="lead">与群众连心，与市场同行，京禧集团致力于为您带来惊喜</p>
            </div>
            <!-- 白色三角形 -->
            <svg class="separator" viewBox="0 0 30 1">
                <polygon points="0,1 30,0 30,1" fill="white"></polygon>
            </svg>
        </section>
        <router-view />
    </main>
</template>

<script>
export default {
    name: 'business'
};
</script>

<style lang="less">
@import '../../style/mixin';

#home-business {
    .bg-jumbotron {
        background-image: url(~@a/business/landing.jpg);
        .p-y(12rem);

        .separator {
            position: absolute;
            bottom: 0;
            z-index: 3;
        }
    }
}
</style>